<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">DMPA-SC Self-Paced Online Training Dashboard</h3>

      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div
        style="position:absolute;margin:0 auto;left:50%,right:50%;"
        v-if="loading"
      >
        Loading...
      </div>
      <v-row class="mt-5" v-show="true">
        <v-col>
          <v-select
            :items="genderTypes"
            item-text="name"
            item-value="value"
            label="Gender"
            v-model="nGender"
            @change="runReport()"
            multiple
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="maritalStatusTypes"
            item-text="name"
            item-value="value"
            label="Marital Status"
            v-model="nMaritalStatus"
            @change="runReport()"
            multiple 
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="ageList"
            item-text="name"
            item-value="value"
            label="Age"
            v-model="nAge" 
            @change="runReport()"           
            multiple
            required
          ></v-select>
        </v-col>
        <v-col v-if="nCountryCode == '' || nCountryCode == 'MM'">
          <v-select
            :items="stateRegions"
            item-text="name"
            item-value="value"
            label="State Region"
            v-model="nStateRegion"      
            @change="runReport()"  
            multiple    
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="countries"
            item-text="name"
            item-value="value"
            label="Country"
            v-model="nCountryCode"
            @change="runReport()"
            multiple
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="pYears"
            item-text="name"
            item-value="value"
            label="Year"
            v-model="nYear"
            @change="runReport()"
            multiple
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
          <v-col>
            <v-btn
            x-small
            color="third"
            dark
            @click="resetTrainingForm()"
            >Reset
          </v-btn>
        </v-col>
        </v-row>

      <v-row>
        <v-col>
          <h4>Training Status</h4>
          <BarChart
            chart-id="chartTraining"
            :chartData="chartTrainingData1"
            :displayLegend="false"
            v-if="chartLoaded1"
          />
          <v-progress-linear
            indeterminate
            v-show="pTestResultLoading2"
            color="purple"
          ></v-progress-linear>
          <div class="d-flex align-end flex-column">
            <v-btn
              v-if="chartLoaded1"
              x-small
              color="secondary"
              dark
              @click="downloadasImage('chartTraining', 'Training Status')"
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Pre test scoring</h4>
          <p>Total participants : {{ totalPreTest }}</p>
          <BarChart
            chart-id="piePreTestTraining"
            :chartData="piePreTestTrainingData"
            :displayLegend="false"
            v-if="piePreTestLoaded"
          />
          <v-progress-linear
            indeterminate
            v-show="pTestResultLoading"
            color="purple"
          ></v-progress-linear>
          <div class="d-flex align-end flex-column">
            <v-btn
              v-if="piePreTestLoaded"
              x-small
              color="secondary"
              dark
              @click="downloadasImage('piePreTestTraining', 'Pre Test')"
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>DMPA-SC Training Status by Lecture</h4>
          <BarChart
            chart-id="pTotalLectureCompletedChart"
            :chartData="pTotalLectureCompletedChartData"
            :displayLegend="false"
            v-if="pTotalLectureCompletedChartLoaded"
          />
          <v-progress-linear
            indeterminate
            v-show="pTotalLectureCompletedResultLoading"
            color="purple"
          ></v-progress-linear>
          <div class="d-flex align-end flex-column">
            <v-btn
              v-if="pTotalLectureCompletedChartLoaded"
              x-small
              color="secondary"
              dark
              @click="
                downloadasImage(
                  'pTotalLectureCompletedChart',
                  'DMPA-SC Training Completion Status by Lecture'
                )
              "
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Post-test completed provider</h4>
          <p>Total participants : {{ totalPostTest }}</p>
          <BarChart
            chart-id="pPostTestSuccessChart"
            :chartData="pPostTestSuccessChartData"
            :displayLegend="false"
            v-if="PostTestChartLoaded"
          />
          <v-progress-linear
            indeterminate
            v-show="pTestResultLoading"
            color="purple"
          ></v-progress-linear>
          <div class="d-flex align-end flex-column">
            <v-btn
              v-if="PostTestChartLoaded"
              x-small
              color="secondary"
              dark
              @click="
                downloadasImage(
                  'pPostTestSuccessChart',
                  'PostTest Participants'
                )
              "
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>DMPA-SC Training - Completion Time</h4>
          <p>Total participants : {{ totalPostTest }}</p>
          <BarChart
            chart-id="pTotalProgramSpendingTimeChart"
            :chartData="pTotalProgramSpendingTimeChartData"
            :displayLegend="false"
            v-if="pTotalProgramSpendingTimeChartLoaded"
          />
          <v-progress-linear
            indeterminate
            v-show="pTotalProgramSpendingTimeChartLoading"
            color="purple"
          ></v-progress-linear>
          <div class="d-flex align-end flex-column">
            <v-btn
              v-if="pTotalProgramSpendingTimeChartLoaded"
              x-small
              color="secondary"
              dark
              @click="
                downloadasImage(
                  'pTotalProgramSpendingTimeChart',
                  'DMPA-SC Training - Completion Time'
                )
              "
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>PreTest Question Correct/InCorrect</h4>
          <BarChart
            chart-id="pTotalQuestionCorrectChart"
            :chartData="pTotalQuestionCorrectChartData"
            :tickDisplay="false"
            v-if="pTotalQuestionCorrectChartLoaded"
          />
          <v-progress-linear
            indeterminate
            v-show="pTotalQuestionCorrectCountLoading"
            color="purple"
          ></v-progress-linear>
          <div class="d-flex align-end flex-column">
            <v-btn
              v-if="pTotalQuestionCorrectChartLoaded"
              x-small
              color="secondary"
              dark
              @click="
                downloadasImage(
                  'pTotalQuestionCorrectChart',
                  'Pretest Question Correct/InCorrect'
                )
              "
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>PostTest Question Correct/InCorrect</h4>
          <BarChart
            chart-id="postQuestionCorrectCountChart"
            :chartData="pTotalPostQuestionCorrectChartData"
            :tickDisplay="false"
            v-if="pTotalPostQuestionCorrectChartLoaded"
          />
          <v-progress-linear
            indeterminate
            v-show="pTotalPostQuestionCorrectCountLoading"
            color="purple"
          ></v-progress-linear>
          <div class="d-flex align-end flex-column">
            <v-btn
              v-if="pTotalPostQuestionCorrectChartLoaded"
              x-small
              color="secondary"
              dark
              @click="
                downloadasImage(
                  'postQuestionCorrectCountChart',
                  'PostTest Question Correct/InCorrect'
                )
              "
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import {
  FETCH_TEST_RESULT_COUNT,
  FETCH_TRAINING_STARTED_COUNT,
  FETCH_SUCCESS_TEST_RESULT_COUNT,
  FETCH_TOTAL_LECTURE_COMPLETED_COUNT,
  FETCH_QUESTION_CORRECT_COUNT,
  FETCH_PROGRAM_SPENDING_TIME,
  FETCH_COUNTRY_LIST,
} from "../store/actions.type";
import BarChart from "@/components/MiladyBarChart.vue";
// import TestCountBarChart from "@/components/TestCountBarChart.vue";
import {countryList,userTypes,providerTypes, genderTypes,ageList,maritalStatusTypes,stateRegions, pYears}  from "../common/ref_data";

export default {
  name: "Training",
  components: {
    BarChart,
    // TestCountBarChart,
  },
  data() {
    return {
      pieComponentKey: 0,
      pTestResultLoading: false,
      pieComponentKey2: 0,
      pTestResultLoading2: false,
      trainees: [],
      genderTypes: genderTypes,
      countries: countryList,
      userTypes: userTypes,
      providerTypes: providerTypes,
      ageList: ageList,
      stateRegions: stateRegions,
      pYears:pYears,
      maritalStatusTypes: maritalStatusTypes,
      piePreTestTrainingData: {},
      piePostTestTrainingData: {},
      piePreTestLoaded: false,
      PostTestChartLoaded: false,
      chartTrainingData1: {},
      chartLoaded1: false,
      pPostTestSuccessChartData: {},
      pTotalLectureCompletedChartData: {},
      pTotalLectureCompletedChartLoaded: false,
      pTotalLectureCompletedResultLoading: false,
      pTotalQuestionCorrectChartData: {},
      pTotalQuestionCorrectChartLoaded: false,
      pTotalQuestionCorrectCountLoading: false,
      pTotalPostQuestionCorrectChartData: {},
      pTotalPostQuestionCorrectChartLoaded: false,
      pTotalPostQuestionCorrectCountLoading: false,
      pTotalProgramSpendingTimeChartData: {},
      pTotalProgramSpendingTimeChartLoaded: false,
      pTotalProgramSpendingTimeChartLoading: false,
      info: null,
      loading: true,
      errored: false,
      totalPreTest: 0,
      totalPostTest: 0,
      totalTrainingData: 0,
      totalLectureData: 0,
      nSearch: "",
      nGender: null,
      nMaritalStatus: null,
      nAge: null,
      nYear: null,
      nStateRegion: null,
      nCountryCode: null,
      nUserType: null,
      nProviderType: null,
    };
  },
  computed: {},
  mounted() {
    this.getCountryList();
    this.getTrainingPieData();
    this.getTrainingStartedCount();
    this.getPostTestSuccessCount();
    this.getTotalLectureCompleted();
    //PreTest Question Count
    this.getTotalQuestionCorrectCount(1);
    //PostTest Question Count
    this.getTotalQuestionCorrectCount(2);
    //get program spending time
    this.getTotalProgramSpendingTime();
  },
  created() {},
  filters: {
    percentageScore: function (value) {
      if (!value) return "";
      return Math.round((value / 10) * 100);
    },
  },
  methods: {
    resetTrainingForm(){
      this.nLoadingData = false;
      this.nAge = null;
      this.nGender = null;
      this.nStateRegion  = null;
      this.nMaritalStatus = null;
      this.nProviderType = null;
      this.nCountryCode = null;
      this.nYear = null;
      this.runReport();
    },
    runReport(){
      this.getTrainingStartedCount();
      this.getTrainingPieData();
      this.getPostTestSuccessCount();
      this.getTotalLectureCompleted();
      this.getTotalQuestionCorrectCount(1);
      this.getTotalQuestionCorrectCount(2);
      this.getTotalProgramSpendingTime();
    },
    getCountryList(){
      var payload = { userType : 'p' };
      var newCountryList = [{ name: "Select Country", value: '--Select--' }];
      this.$store
        .dispatch(FETCH_COUNTRY_LIST,payload)
        .then((data) => {
          if(data.length> 0){
            for(let i = 0; i < data.length; i++){
              newCountryList.push(data[i]);
            }
          }
        }).finally(() => {
          this.countries = newCountryList;
        });
    },
    getTrainingStartedCount() {
      this.pTestResultLoading2 = true;
      var defaultChartData = {
        labels: [
          "Total registration provider",
          "Pre-test completed provider",
          "Lecture 1 completed provider",
          "Lecture 6 completed provider",
          "Post-test completed provider",
        ],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "",
            color: "#ffffff",
            backgroundColor: "#FF2E74",
            data: [0, 0, 0],
            borderWidth: 1,
          },
        ],
      };
      var payload = {
        age: this.nAge,
        state_region: this.nStateRegion,
        gender: this.nGender,
        marital_status: this.nMaritalStatus,
        provider_type : this.nProviderType,
        country_code: this.nCountryCode,
        year: this.nYear,
      };
      this.$store
        .dispatch(FETCH_TRAINING_STARTED_COUNT,payload)
        .then((data) => {
          this.pTestResultLoading2 = false;
          this.testResultLoading2 = false;
          if (data != null) {
            defaultChartData.datasets[0].data[0] =
              data[0].total_training_not_started;
            defaultChartData.datasets[0].data[1] =
              data[0].total_training_started;
            defaultChartData.datasets[0].data[2] = data[0].total_lecture1;
            defaultChartData.datasets[0].data[3] = data[0].total_lecture6;
            defaultChartData.datasets[0].data[4] =
              data[0].total_training_completed;
            this.chartTrainingData1 = defaultChartData;
            this.chartLoaded1 = true;
            // this.totalTrainingData =
            //   parseInt(data[0].total_training_not_started) +
            //   parseInt(data[0].total_training_started) +
            //   parseInt(data[0].total_training_completed);
          }
          this.pieComponentKey2 += 1;
        })
        .catch((response) => {
          console.log(response);
          this.errored = true;
          this.pTestResultLoading2 = false;
        })
        .finally(() => {
          this.errored = false;
          this.pTestResultLoading2 = false;
        });
    },
    getTrainingPieData() {
      this.pTestResultLoading = true;
      var defaultPiePreTestData = {
        labels: ["PreTest >= 80 marks", "PreTest < 80 marks"],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "",
            backgroundColor: ["#EF5DA8"],
            data: [0, 0],
          },
        ],
      };
      var defaultPiePostTestData = {
        labels: ["PostTest >=80 marks", "PostTest < 80 marks"],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            backgroundColor: ["#7e13a1"],
            data: [0, 0],
          },
        ],
      };
      var payload = {
        age: this.nAge,
        country_code: this.nCountryCode,
        state_region: this.nStateRegion,
        gender: this.nGender,
        marital_status: this.nMaritalStatus,
        provider_type : this.nProviderType,
        year: this.nYear,
      };
      this.$store
        .dispatch(FETCH_TEST_RESULT_COUNT,payload)
        .then((data) => {
          this.pTestResultLoading = false;
          this.testResultLoading = false;
          if (data != null) {
            defaultPiePreTestData.datasets[0].data[0] =
              data[0].total_pretest_success;
            defaultPiePreTestData.datasets[0].data[1] =
              data[0].total_pretest_failed;
            defaultPiePostTestData.datasets[0].data[0] =
              data[0].total_posttest_success;
            defaultPiePostTestData.datasets[0].data[1] =
              data[0].total_posttest_failed;
            this.piePreTestTrainingData = defaultPiePreTestData;
            this.piePostTestTrainingData = defaultPiePostTestData;
            this.piePreTestLoaded = true;
            this.PostTestChartLoaded = true;
            this.totalPreTest =
              parseInt(data[0].total_pretest_success) +
              parseInt(data[0].total_pretest_failed);
          }
          this.pieComponentKey += 1;
        })
        .catch((response) => {
          console.log(response);
          this.errored = true;
          this.pTestResultLoading = false;
        })
        .finally(() => {
          this.errored = false;
          this.pTestResultLoading = false;
        });
    },
    getPostTestSuccessCount() {
      var defaultSuccessPostTestData = {
        labels: [
          "1 Time",
          "2 Times",
          "3 Times",
          "4 Times",
          "5 Times",
          "6 Times",
          "7 Times",
          "8 Times",
          "9 Times",
          "10 Times",
          "11 Times",
          "12 Times",
          "13 Times",
          "14 Times",
          "15 Times",
        ],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "",
            backgroundColor: ["#7e13a1"],
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      };
      var payload = {
        age: this.nAge,
        country_code: this.nCountryCode,
        state_region: this.nStateRegion,
        gender: this.nGender,
        marital_status: this.nMaritalStatus,
        provider_type : this.nProviderType,
        year: this.nYear,
      };
      this.totalPostTest = 0;
      this.$store
        .dispatch(FETCH_SUCCESS_TEST_RESULT_COUNT,payload)
        .then((data) => {
          this.loading = false;
          if (data != null) {
            var result = [];
            result = data;
            console.log(FETCH_SUCCESS_TEST_RESULT_COUNT);
            console.log(result);
            for (const p of result) {
              var index = p.times ;
              defaultSuccessPostTestData.datasets[0].data[index-1] = p.total;
              this.totalPostTest += parseInt(p.total);
            }
            this.pPostTestSuccessChartData = defaultSuccessPostTestData;
          }
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    getTotalLectureCompleted() {
      this.pTotalLectureCompletedResultLoading = true;
      var defaultData = {
        labels: [],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "",
            backgroundColor: ["#7e13a1"],
            data: [],
          },
        ],
      };
      var payload = {
        age: this.nAge,
        country_code: this.nCountryCode,
        state_region: this.nStateRegion,
        gender: this.nGender,
        marital_status: this.nMaritalStatus,
        provider_type : this.nProviderType,
        year: this.nYear,
      };
      this.totalLectureData = 0;
      this.$store
        .dispatch(FETCH_TOTAL_LECTURE_COMPLETED_COUNT,payload)
        .then((data) => {
          this.loading = false;
          if (data != null) {
            console.log(data);
            var result = [];
            result = data;
            for (const p of result) {
              defaultData.labels.push(p.lecture);
              defaultData.datasets[0].data.push(p.totalTrainee);
              this.totalLectureData += parseInt(p.totalTrainee);
            }
            this.pTotalLectureCompletedChartData = defaultData;
            this.pTotalLectureCompletedChartLoaded = true;
          }
          this.pTotalLectureCompletedResultLoading = false;
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
          this.pTotalLectureCompletedResultLoading = false;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
          this.pTotalLectureCompletedResultLoading = false;
        });
    },
    getTotalQuestionCorrectCount(type) {
      if (type == 2) {
        this.pTotalPostQuestionCorrectCountLoading = true;
      } else {
        this.pTotalQuestionCorrectCountLoading = true;
      }
      var defaultData = {
        labels: [],
        datasets: [
          {
            label: "Correct",
            backgroundColor: ["#028342"],
            data: [],
          },
          {
            label: "Incorrect",
            backgroundColor: ["#D80000"],
            data: [],
          },
        ],
      };
      var payload = {
        age: this.nAge,
        country_code: this.nCountryCode,
        state_region: this.nStateRegion,
        gender: this.nGender,
        marital_status: this.nMaritalStatus,
        provider_type : this.nProviderType,
        year: this.nYear,
        type: type,
      };
      this.$store
        .dispatch(FETCH_QUESTION_CORRECT_COUNT, payload)
        .then((data) => {
          this.loading = false;
          if (data != null) {
            var result = [];
            result = data;
            console.log(result);
            var questionId = "";
            for (var i = 0; i < result.length; i++) {
              if (questionId != result[i].questionId) {
                questionId = result[i].questionId;
                defaultData.labels.push(
                  result[i].questionId + "." + result[i].question_text
                );
              }
              if (
                questionId == result[i].questionId &&
                result[i].checkAnswer == "CORRECT"
              ) {
                defaultData.datasets[0].data.push(result[i].total);
              }
              if (
                questionId == result[i].questionId &&
                result[i].checkAnswer == "INCORRECT"
              ) {
                defaultData.datasets[1].data.push(result[i].total);
              }
            }
            if (type == 2) {
              this.pTotalPostQuestionCorrectChartData = defaultData;
              this.pTotalPostQuestionCorrectChartLoaded = true;
              console.log(defaultData);
            } else {
              this.pTotalQuestionCorrectChartData = defaultData;
              this.pTotalQuestionCorrectChartLoaded = true;
            }
          }
          if (type == 2) {
            this.pTotalPostQuestionCorrectCountLoading = false;
          } else {
            this.pTotalQuestionCorrectCountLoading = false;
          }
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
          this.pTotalQuestionCorrectCountLoading = false;
          this.pTotalPostQuestionCorrectCountLoading = false;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
          this.pTotalQuestionCorrectCountLoading = false;
          this.pTotalPostQuestionCorrectCountLoading = false;
        });
    },
    getTotalProgramSpendingTime() {
      this.pTotalProgramSpendingTimeChartLoading = true;
      var defaultData = {
        labels: [
          "=<168 Hrs ",
          "169-336 Hrs",
          "337-504 Hrs",
          "505-672 Hrs",
          ">=673 Hrs",
        ],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "",
            backgroundColor: ["#D30058"],
            data: [],
          },
        ],
      };
      var payload = {
        age: this.nAge,
        country_code: this.nCountryCode,
        state_region: this.nStateRegion,
        gender: this.nGender,
        marital_status: this.nMaritalStatus,
        provider_type : this.nProviderType,
        year: this.nYear,
      };
      this.$store
        .dispatch(FETCH_PROGRAM_SPENDING_TIME,payload)
        .then((data) => {
          this.loading = false;
          if (data != null) {
            var result = [];
            result = data;
            for (var i = 0; i < result.length; i++) {
              defaultData.datasets[0].data.push(result[i]);
            }
          }
          console.log("This is program spending time");
          this.pTotalProgramSpendingTimeChartData = defaultData;
          console.log(this.pTotalProgramSpendingTimeChartData);
          this.pTotalProgramSpendingTimeChartLoading = false;
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
          this.pTotalProgramSpendingTimeChartLoading = false;
          this.pTotalProgramSpendingTimeChartLoaded = false;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
          this.pTotalProgramSpendingTimeChartLoading = false;
          this.pTotalProgramSpendingTimeChartLoaded = true;
        });
    },
    downloadasImage(chart, chartTitle) {
      var canvas = document.getElementById(chart);
      const link = document.createElement("a");
      link.download = `${chartTitle}.png`;
      link.href = canvas.toDataURL("image/png", 1);
      link.click();
    },
  },
};
</script>
